import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import {ListItemSecondaryAction} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
// import Typography from '@material-ui/core/Typography';
import '../App.css'
import coin from '../assets/money.png';
import { deepPurple } from '@material-ui/core/colors';
import {getProfile} from '../util/utility'
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  }
}));

export default function BoardItem({data}) {
  const classes = useStyles();
  const [profile, setProfile] = useState({});
  useEffect(() => {
    fetchData()
  }, []);


  async function fetchData() {
    const data = await getProfile();
    setProfile(data);
  }

// console.log(data)
  return (
      <ListItem alignItems="flex-start" style={{background:(data.player.msisdn === profile.msisdn)?'#f4edf9':''}}>
          {/* <div className="score">{data.pos}</div> */}
        <ListItemAvatar >
          <Avatar className={classes.purple}>{data.pos}</Avatar>
        </ListItemAvatar>
        
        <ListItemText
          primary={data.player.username}
          style={{marginTop:15}}
        />
         <ListItemSecondaryAction>
         <span className="coin">{data.player.current_tokens}</span> &nbsp; <img src={coin} width={28} style={{verticalAlign:'middle'}} />
            </ListItemSecondaryAction>
      </ListItem>
 
  );
}
