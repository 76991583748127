import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getAppSettings } from './util/utility';

import { AppContext } from './contexts/App'

async function init() {

  try {
    const settings = await getAppSettings();

    ReactDOM.render(

      <React.StrictMode>
        <AppContext.Provider value={settings}>
          <App />
        </AppContext.Provider>

      </React.StrictMode>,
      document.getElementById('root')
    );
    // serviceWorker.unregister();
 
  } catch (e) {
    console.error(e);
    init();
  }



} 
init();

  

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


