import React, { useState, useEffect } from "react";
// import banner from "../assets/ARENAGAMES.png";
import { Grid, Paper, Typography, Button, Chip } from "@material-ui/core";
// import { myStyles } from "../components/styles";

// import { dataProvider } from '../util/utility';
// import BoardItem from '../components/BoardItem'
import '../App.css';
import { Person } from '@material-ui/icons';
import coin from '../assets/money.png';
import { AlertTitle, Alert } from '@material-ui/lab';
import { getLastPP, getProfile, myPowerPlays, getFullProfile, dataProvider_post, api_file, myLoader } from "../util/utility";
// import { myStyles } from "../components/styles";
import Swal from 'sweetalert2'
import { useHistory } from "react-router";

function Account() {
  // const classes = myStyles();
  // const classes = myStyles();
  const [pp, setPP] = useState([]);
  const [lastpp, setLastPP] = useState({});
  // const [game, setGame] = useState({});
  const [profile, setProfile] = useState({});
  const [loaded, setLoaded] = useState(false);
  const history = useHistory();
  const [fullProfile, setFullProfile] = useState({});

  // const [game, setGame] = useState({});
  useEffect(() => {
    fetchData()
    // fetchData2()
  }, []);


  useEffect(() => {
    // console.log(fullProfile, "ff")
  }, [fullProfile]);


  // async function fetchData2(){
  //   const data = await currentGame();
  //   console.log(data.data.data)
  //   setGame(data.data.data);
  // }

  // const playGame = () => {
  //   window.location = game?.href;
  // }

  async function fetchData() {

    try {
      loadDataFetch()
    } catch (error) {
      console.error(error);
      loadDataFetch();
    }

  }

  async function loadDataFetch() {
    try {
      const data = await getProfile();
      const fullprofile = await getFullProfile();
      const last_pp = await getLastPP();
      // console.log(fullprofile.data, "fullprofile")
      setLastPP(last_pp);
      setFullProfile(fullprofile.data);
      setProfile(data);
      fetchPP();
      setLoaded(true)
    } catch (error) {
      console.error(error);

    }


  }


  const doUnsub = () => {

    Swal.fire({
      title: 'Are you sure?',
      text: "Unsubscribe",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {

        myLoader();
        dataProvider_post("https://www.mydigicellive.com/ms/arena/unsub.php", { m: profile?.msisdn }).then(res => {
          // console.log(res, "fetch");
          Swal.close();
          if (!res.data) return false;
          const { data } = res;
          Swal.fire(
            // 'Deleted!',

            'ArenaGames',
            data,
            'info'
          ).then(() => {
            // console.log(res)
            window.location.reload();
          })

        }).catch(err => {
          Swal.close();
          console.log(err)
        })
      }
    })


  }


  const doPayment = () => {
    myLoader();
    dataProvider_post(api_file + "?name=do-pay", { op: "pay" }).then(res => {
      // console.log(res.data, "fetch");

      if (res.data.error) {
        Swal.fire(
          'ArenaGames',
          data.message,
          'warning'
        )
      }

      const { data } = res.data;
      Swal.fire(
        // 'Deleted!',

        'ArenaGames',
        data.message,
        'info'
      ).then(() => {
        // console.log(res.data)
        window.location.reload();
      }).catch((err) => {
        console.log(err, "payment")
      })

    }).catch(err => {
      console.log(err.response, "catch")
    })
  }

  // const doSub = () => {
  //   dataProvider_post("https://www.mydigicellive.com/ms/arena/subscribe.php", { p: "arenagames" }).then(res => {
  //     // console.log(res.data, "fetch");
  //     Swal.fire(
  //       'ArenaGames',
  //       res.data,
  //       'info'
  //     ).then(() => {
  //       window.location.reload();
  //     })

  //   }).catch(err => {
  //     console.log(err)
  //   })

  // }


  async function fetchPP() {
    const data = await myPowerPlays();
    // console.log(data.data.data, 'pooowweer')
    setPP(data.data.data);
    // setImg(`https://www.mydigicellive.com/zero/html5_games/${game?.id}_digicellive/media/graphics/promo/banners/440x280.png`);
  }

  const playGame = (url) => {
    window.location = url;
  }

  var icnt = 0;
  return loaded ? (
    <div className="body">
      <div style={{ padding: 20, background: '#eee' }}>
        <Grid container>


          <Grid item xs={12}>
            <Person style={{ color: '#B10FBC', fontSize: 34 }} />
            <small style={{ color: "#7c0784", verticalAlign: "top", marginLeft: 5 }}>
              My Account <Chip label={profile?.msisdn} />
            </small>
          </Grid>
          <Grid item xs={12}>
            {fullProfile?.sub === true ? <Button onClick={doUnsub} color="secondary" variant="outlined">Unsubscribe</Button> : ''}

          </Grid>
          
      
        </Grid>
      </div>

      <Grid container >
           {
            lastpp?.data[0]?.purchase_code !== null && lastpp?.data[0]?.purchase_code !== undefined? <Alert onClick={() => { history.push('/powerplay?id=' + lastpp?.data[0]?.purchase_code) }} style={{ marginTop: 5 }} severity="success" color="success">
              Click here to gain up to <img src={coin} width={17} style={{ verticalAlign: 'middle' }} /> <b>210</b>. Get a Score Booster.
            </Alert> : ''
          }

          </Grid>


      <Paper className="card" style={{ background: 'linear-gradient(to left, #743ad5, #d53a9d)', color: 'white' }}>
        <Typography variant="h6">
          Hey {profile?.username}, you have <img src={coin} width={28} style={{ verticalAlign: 'middle' }} /> <span className="coin">{profile?.current_tokens}</span> tokens
        </Typography>
      </Paper>

      <Grid container spacing={2}>
        {/* <Grid item xs={12}>
        <h4>Notifications</h4>
        <Divider/>
    </Grid> */}

        {
          fullProfile?.payment_active === false && fullProfile?.sub === true ? (<Grid item xs={12}>
            <Alert severity="error" action={
              <Button onClick={doPayment} color="inherit" size="small" color="primary" variant="contained">
                Pay now
              </Button>
            }>
              <AlertTitle>Billing Error</AlertTitle>
              Your payment <strong> is due</strong>
            </Alert>

          </Grid>) : null
        }


        {
          fullProfile?.sub === false && fullProfile.rates.subscription >= 0 ? (<Grid item xs={12}>
            <Alert severity="error" action={
              <Button onClick={doPayment} color="inherit" size="small" color="primary" variant="contained">
                Subscribe
              </Button>
            }>
              <AlertTitle>ArenaGames Subscription</AlertTitle>
              You must be subscribed to play <strong>ArenaGames</strong>. Please confirm your free subscription.
              {/* Daily subscription rate is ${fullProfile.rates.subscription / 100} */}
            </Alert>
            

          </Grid>) : (!loaded ? <Alert severity="error">Could not read subscription rates. Please try again.</Alert> : '')
        }



        <Grid item xs={12}>
          <Alert severity="info" color="info">
            Total Games played: <b>{profile?.games_played}</b>
          </Alert>
          <Alert style={{ marginTop: 5 }} severity="info" color="info">
            Total Tokens earned:  <b>{profile?.total_tokens}</b>
          </Alert>

        



          <div className="para center">

            {/* <Button
          variant="outlined"
          size="large"
          className={classes.digiButton}
          onClick={playGame}

        >PLAY &amp; WIN
       </Button> */}

            {/* <small><br/>Game ends in 3 days</small> */}
            {/* {game?.time?.days_left !== undefined ? <Chip style={{ marginTop: 10 }} label={"Game ends in " + game?.time?.days_left + " days"} color="default" variant="outlined" /> : ''} */}
          </div>
        </Grid>

        {pp?.length > 0 ?

          <Grid item xs={12}>
            <div style={{ marginBottom: 20 }}>
              <h4 style={{ color: "#7c0784", marginLeft: 5, paddingBottom: 0, marginBottom: 0 }}>
                My Score Boosters
              </h4>
              <small style={{ color: "#7c0784", marginLeft: 5 }}>
                Earn Score Boosters by beating the target scores
              </small>

            </div>

            {
              pp?.map(item =>
                <Paper key={icnt++} onClick={() => playGame(`http://www.mydigicellive.com/zero/html5_games/${item.game_id}_digicellive`)} >
                  {/* {item.game_id} */}
                  <img alt="game" style={{ display: 'inline-block', verticalAlign: 'middle' }} src={`https://www.mydigicellive.com/zero/html5_games/${item.game_id}_digicellive/media/graphics/promo/banners/440x280.png`} onError={(e) => e.target.src = `https://www.mydigicellive.com/zero/html5_games/${item.game_id}_digicellive/media/graphics/promo/banners/440x280.jpg`} style={{ width: '64px', verticalAlign: 'middle' }} />&nbsp;<Typography style={{ display: 'inline-block' }} component="h6">
                    {item?.title?.substring(0, 29) +
                      (item?.title?.length > 29 ? "..." : "")}
                  </Typography>
                </Paper>
              )
            }

          </Grid> : ''}


      </Grid>



    </div>
  ) : null

}



export default Account;