import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
// import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import logo from "../assets/ARENAGAMES.png";
import gn_logo from "../assets/gamenation.png";
// import { useHistory } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import BackIcon from "@material-ui/icons/ChevronLeft";
// import {authProvider } from "../util/utility";

// import {
//   // getBrowserLocales as lang,
//   // getLanguage,
//   setLanguage,
//   supportedLanguages,
// } from "../util/utility";
// import {logOut} from './session';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    // marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    background: "#dedede",
    border:"1px solid #ccc"
    // background: "linear-gradient(45deg, #9a9a9a 34%, #eee 70%)",
  },
}));

function Bar(props) {
  const history = useHistory();

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [lang, setLang] = React.useState(null);
  // const [langAnchorEl, setLangAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // const openLang = Boolean(langAnchorEl);
  // let history = useHistory();
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // const handleLangMenu = (event) => {
  //   setLangAnchorEl(event.currentTarget);
  // };

  // const menuClick = (op) => {
  //   console.log(op);
  //   if (op === "logout") {
  //     //   logOut();
  //     history.push("/login");
  //   }
  //   setAnchorEl(null);
  // };

  const handleClose = (e) => {
    setAnchorEl(null);
  };
  // const handleLangClose = (e) => {
  //   // console.log(e.target)

  //   // console.log(supportedLanguages.code[e.target.value]);
  //   setLanguage(supportedLanguages.code[e.target.value]);
  //   window.location.reload();
  //   setLangAnchorEl(null);
  // };
  // const handleLangExit = (e) => {
  //   setLangAnchorEl(null);
  // };
  const goHome = () => {
    history.push("/");
  };

  return (
    <div>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          {props.data?.back? (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="back"
              onClick={()=>{history.goBack();}}
            >
              <BackIcon style={{fontSize:'1.2em'}} />
            </IconButton>
          ) : (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleMenu}
            >
              <MenuIcon style={{color:'#262626'}} />
            </IconButton>
          )}

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={()=> {history.push('/faq');handleClose()}}>FAQ</MenuItem>
            <MenuItem onClick={()=> {history.push('/account');handleClose()}}>My Account</MenuItem>
            {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
            {/* <MenuItem onClick={() => {authProvider.logout();handleClose()}}>Logout</MenuItem> */}
          </Menu>

          <Typography variant="h6" className={classes.title} onClick={goHome}>
            <img src={logo} className="logo" alt="logo" style={{height:36}} />  <img src={gn_logo} className="logo" alt="gamenation logo" style={{height:15}} />
          </Typography> 
          
           <div>
            {/* <img src={gn_logo} className="logo" alt="gamenation logo" style={{height:24}} /> */}
          </div>
          {/* <IconButton
            edge="end"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleLangMenu}
          >
            {getLanguage().toUpperCase()}
          </IconButton> */}
          {/* <Menu
            id="simple-menu"
            anchorEl={langAnchorEl}
            keepMounted
            open={openLang}
            onClose={handleLangExit}
          >
            {supportedLanguages.name.map((value, index) => (
              <MenuItem key={index} onClick={handleLangClose} value={index}>
                {value}
              </MenuItem>
            ))}
          </Menu> */}
        </Toolbar>
      </AppBar>
    </div>
  );
}
export default Bar;