import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { checkAuth } from "../util/utility";
import LinearProgress from '@material-ui/core/LinearProgress';

const UnauthRoute = ({
    component: Component,
    permission,
    ...rest
}) => {
    const [authStatus, setAuthStatus] = React.useState('loading');
    // username: "", permission: 0 
    useEffect(() => {
        doCheck();
        // console.log(Component);
        // console.log(permission)
    }, []);
    
    useEffect(() => {       
        // console.log(authStatus, "AUTHSTAS");
    }, [authStatus]);

    async function doCheck() {
        try {
              var profile = await checkAuth();
        // console.log(profile.data, "por")

        //if profile exists then update seesion
        if(profile.data.data !== false){
           sessionStorage.setItem("profile", JSON.stringify(profile.data.data)); 
            
        }

        setAuthStatus(profile.data.data);
        } catch (error) {
            console.log(error)
        }

      
    }

    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route
            {...rest}
            render={(props) => (
                <React.Fragment>

                    {authStatus === 'loading' ? (<div><LinearProgress color="secondary" /></div>) : (
                        <React.Fragment>
                            {authStatus !== false && authStatus !== undefined ? (   //is a member  
                               
                            <Redirect to="/play" />                     
                                
                            ) : (
                                <React.Fragment>
                                <Component {...props} />                            
                            </React.Fragment>  
                            )}
                        </React.Fragment>
                    )}
                </React.Fragment>

            )}
        />
    );
};

export default UnauthRoute;