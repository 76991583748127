import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { checkAuth, getGameSession } from "../util/utility";
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import {useHistory} from 'react-router-dom'
const PrivateRoute = ({
    component: Component,
    permission,
    ...rest
}) => {
    const history = useHistory();
    const [authStatus, setAuthStatus] = React.useState('loading');
    const [session, setSession] = React.useState();
    // username: "", permission: 0 
    useEffect(() => {
        fetchGameSession();
        doCheck();

        // console.log(Component);
        // console.log(permission)
    }, []);

    // useEffect(() => {
        // console.log(authStatus, "AUTHSTAS");
    // }, [authStatus]);

    async function doCheck() {
        try {
            var profile = await checkAuth();
            // console.log(profile.data, "checkAUth PROFILE")
            profile = profile.data.data.profile;
            //if profile exists then update seesion
            // if (profile.data.data !== false) {
            if (profile) {
                sessionStorage.setItem("profile", JSON.stringify(profile));

            } else {
                sessionStorage.setItem("profile", '');
                history.push('/register');
            }

            setAuthStatus(profile);
        } catch (error) {
            console.log(error)
        }
    }

    async function getFullProfile() {
        try {
            var profile = await checkAuth();
            // console.log(profile.data.data.profile, "checkAUth PROFILE")
            profile = profile.data.data;
            //if profile exists then update seesion
            // if (profile.data.data !== false) {
            if (profile) {
                return profile;

            } else {
                sessionStorage.setItem("profile", '');
                history.push('/register');
            }

            setAuthStatus(profile);
        } catch (error) {
            console.log(error)
        }
    }

    

    async function fetchGameSession() {
        try {
            var data = await getGameSession();
            // console.log(data, "session struff")
            setSession(data.data[0]);
        } catch (error) {
            console.log(error)
        }
    }

    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route
            {...rest}
            render={(props) => (
                <React.Fragment>

                    {authStatus === 'loading' ? (<div><LinearProgress color="secondary" /></div>) : (

                        session?.id ?
                            <React.Fragment>
                                {authStatus !== false && authStatus !== undefined ? (
                                    <React.Fragment>
                                        <Component {...props} />

                                    </React.Fragment>
                                ) : (
                                    <Redirect to="/register" />
                                )}
                            </React.Fragment> : (
                                <>
                                    <Alert severity="info" color="warning">
                                        Sorry game is closed. We'll be back shortly!
                                    </Alert>
                                </>

                            )
                    )}
                </React.Fragment>

            )}
        />
    );
};

export default PrivateRoute;