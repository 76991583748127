import React, { useState } from 'react';
import useUmami from '@parcellab/react-use-umami';

import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import samplePDF from '../assets/faq.pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;


export default function Faq() {
  const [numPages, setNumPages] = useState(7);
  const [pageNumber, setPageNumber] = useState(1);
  const _trackEvt = useUmami('/') // will fire a page view once
  // call this hook only in the top main component and pass the _track function down where it is needed!

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    _trackEvt('FAQ Loaded') 
  }

  function nextPage(d) {

    if (pageNumber + d === 0 || pageNumber + d === numPages+1) return;
    setPageNumber(pageNumber => (pageNumber + d))
  }

  return (
    <div className="body">
      <div style={{ padding: 5, background: '#eee'}} className="pp">
        <Document file={samplePDF} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} style={{width:'100%'}} width={document.getElementsByClassName('pp')[0]?.clientWidth*0.8 ?? 150} />
        </Document>
        <p style={{textAlign:"center"}}>
          <button onClick={() => nextPage(-1)}>
            Previous page
          </button> <button onClick={() => nextPage(+1)}>
            Next page
          </button>
        </p>
      </div>
    </div>
  )
}

