import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
// import ListItemText from '@material-ui/core/ListItemText';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import { ListItemSecondaryAction } from '@material-ui/core';
// import Avatar from '@material-ui/core/Avatar';
// import Typography from '@material-ui/core/Typography';
import { dataProvider } from '../util/utility'
import BoardItem from './BoardItem'

import '../App.css';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

export default function LeaderBoard() {
  const classes = useStyles();
  const [players, setPlayers] = useState([]);

  const fetchData = async () => {
    dataProvider("GET", "api.php?name=leaderboard", null).then(res => {
      // console.log(res.data.data, "fetch");
      setPlayers(res.data.data);
    }).catch(err => {
      console.error(err)
    })
  }

  useEffect(() => {
    // console.log(1)
    fetchData();
  }, [])

  return (
    <List className={classes.root}>
      <div >
        {
          players?.map((v, i) =>
            <div  key={i+"_item"}>
              <BoardItem data={{ pos:v.rank, player: v }} />
              {/* <BoardItem key={i} data={{ pos: i + 1, player: v }} /> */}
              <Divider variant="inset" component="li" />
            </div>
          )
        }
      </div>

    </List>
  );
}
