import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
// import banner from "../assets/ARENAGAMES.png";
import { Grid, Button, TextField } from "@material-ui/core";
import { myStyles } from "../components/styles";
// import Divider from '@material-ui/core/Divider';
import { dataProvider, currentGame, myLoader } from "../util/utility";
// import { dataProvider } from '../util/utility';
// import BoardItem from '../components/BoardItem'
import '../App.css';
// import { HistoryOutlined, Settings } from '@material-ui/icons';
// import signup from '../assets/signup.png';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
// import MuiAlert from '@material-ui/lab/Alert';

import Swal from 'sweetalert2'

function Register() {

    const [msisdn, setMSISDN] = useState("");
    const [user, setUser] = useState();
    const history = useHistory();
    const classes = myStyles();
    const [open, setOpen] = React.useState(false);
    const [gameArena, setGameArena] = React.useState([]);
    const [img, setImg] = useState();


    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


 

    const handleFormChange = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    };

    // useEffect(() => {
    //     fetchData();
    // }, [])

    const [game, setGame] = useState({});

    useEffect(() => {
        fetchData();
        fetchGame();
    }, [])

    async function fetchGame() {
        const data = await currentGame();
          console.log(data.data.data[0])
        setGame(data.data.data);
    }


    const fetchData = async () => {
        dataProvider("GET", "/api.php?name=get-msisdn", null).then(res => {
            console.log(res.data, "m");
            // console.log(res.data.data, "msisdn");
            if (res?.data.data === null || res.data.data?.length < 8) { // is a member
                // window.location.href = 'https://mydigicellive.com/auth3/?goto=https://arenagames.mydigicellive.com/';
                window.location.href = 'https://mydigicellive.com/auth2/?goto=https://arenagames.mydigicellive.com/';
                return;
            }
            setMSISDN(res.data.data)

            // if(res.data)
        }).catch(err => {
            console.log(err)
        })


        // try {
        //     const data = await currentGame();
        //     // console.log(data.data.data, "games");
        //     setGame(data.data.data);
        //      setImg(`https://www.mydigicellive.com/zero/html5_games/${data.data.data?.id}_digicellive/media/graphics/promo/banners/440x280.png`);  
        // } catch (error) {
        //     console.error(error)
        // }

    }

    const saveUser = async () => {

        //loading
        myLoader();


        //   setTimeout(()=>{Swal.close();}, 3000)
        //   return;
        dataProvider("POST", "/api.php?name=signup", user).then(res => {
            // console.log(res.data.data);
            if (res.data.data[0].msisdn === msisdn) {
                Swal.close();
                gtag('event', 'conversion', { 'send_to': 'AW-10982165912/1-2qCJjVyeADEJib2vQo' });
                setOpen(true);
                setUser(null);
                Array.from(document.querySelectorAll("input")).forEach(
                    (input) => (input.value = "")
                );

                setTimeout(() => history.push('/account'), 2000);
            }
        }).catch(err => {
            console.log(err)
            Swal.close();
        })
    }

    function handleSubmit(event) {
        event.preventDefault();
        // console.log( event.target); 
        saveUser();
        // You should see email and password in console.
        // ..code to submit form to backend here...

    }

    return msisdn ? (
        <div className="body">
            <div style={{ textAlign: 'center' }}>
                {/* <img src={signup} style={{ height: '30vh' }} /> */}

                
                 <img alt={game[0]?.title} src={game[0]?.id ? `https://www.mydigicellive.com/zero/html5_games/${game[0]?.id}_digicellive/media/graphics/promo/banners/440x280.png` : ''
                } style={{ width: '100%' }} /> 
            </div>
            <Grid container >
                <form onSubmit={handleSubmit} >
                    <Grid item sm={12}>
                        {/* <h3 className="h3-title">Welcome to Arena Games</h3> */}
                        <p className="para">Choose a username to continue.</p>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <small>Your mobile number is <b>{msisdn ? msisdn : "NOT FOUND"}</b></small>
                        <TextField name="username" style={{ width: '100%', marginTop: 15 }}
                            autoComplete="off"
                            inputProps={{
                                autoComplete: "off",
                                minLength: 5
                            }}
                            required

                            label="Enter a username" variant="outlined" onChange={handleFormChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <div className="para center">
                            <Button
                                variant="outlined"
                                size="large"
                                type="submit"
                                className={classes.digiButton}
                            // onClick={saveUser}
                            > Save </Button>
                        </div>
                    </Grid>

                </form>
            </Grid>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    Thanks for subscribing to Arena Games.
                </Alert>
            </Snackbar>

        </div>

    ) : <div style={{ textAlign: "center", color: "red" }}>...</div>
}
export default Register;