import Swal from 'sweetalert2'
import loader from "../assets/load.gif";
import axios  from 'axios';

const Cookies = require("js-cookie");

// Now you can set defaults
 axios.defaults.timeout = 12000;


axios.defaults.withCredentials = true;
export const api_file = "api.php";
const axios_136 = axios.create({
  baseURL: "https://www.mydigicellive.com/api/",
  // baseURL: "http://172.16.3.136:5000",
  timeout: 12000,
  // headers: {'X-Custom-Header': 'foobar'}
});

const axios_def = axios.create({
  // baseURL: "https://reqres.in/api",
  baseURL: "https://www.mydigicellive.com/ms/arena/",
  // baseURL: "http://172.16.3.136:5000",
  timeout: 12000,
  // headers: {'X-Custom-Header': 'foobar'}
});

// axios_def.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
export const paths = {
  home: "/",
  games: "/games",
  info: "/digiinfo",
};


export async function getAppSettings() {
  try {
     const response = await axios_def.post(api_file + "?name=appSettings", null);
    return response
  } catch (error) {
    throw new Error(error);
  }
}

/* Info Calls */
export async function getSubscriptions() {
  // console.log("s3");
  try {
    const response = await axios.get(
      "https://www.mydigicellive.com/ms/get_subscriptions.php",
      { withCredentials: true }
    );
    // console.log(response, "---");
    return response;
  } catch (error) {
    throw new Error(error);
  }
}

/* DigiINFO via category */
export async function getInfos(cat) {
  // axios.defaults.baseURL = 'https://newport.digipoint.biz/';
  try {
    const response = await axios.get(
      "https://newport.digipoint.biz/digiinfos?category_eq=" + cat
    );
    // console.log(response);
    return response;
  } catch (error) {
    throw new Error(error);
  }
}
export async function getInfo(id) {
  // axios.defaults.baseURL = 'https://newport.digipoint.biz/';
  try {
    const response = await axios.get(
      "https://newport.digipoint.biz/digiinfos?info_eq=" + id
    );
    // console.log(response);
    return response;
  } catch (error) {
    // console.error(error);
    // return error;
    throw new Error(error);
  }
}

/* Game Subscription Status */
export async function getGameSubscription() {
  try {
    const response = await axios.get(
      "https://mydigicellive.com/ms/echo_subscriptions.php"
    );
    // console.log(response);
    return response;
  } catch (error) {
    throw new Error(error);
  }
}

/* Game Calls */
export async function getGames(cat) {
  try {
    const response = await axios_136.get("games/" + cat);
    // console.log(response);
    return response;
  } catch (error) {
    throw new Error(error);
  }
}
export async function getGame(id) {
  try {
    const response = await axios_136.get("game/" + id);
    // console.log(response);
    return response;
  } catch (error) {
    // console.error(error);
    // return error;
    throw new Error(error);
  }
}

export function getBrowserLocales(options = {}) {
  const defaultOptions = {
    languageCodeOnly: true,
  };

  const opt = {
    ...defaultOptions,
    ...options,
  };

  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;

  if (!browserLocales) {
    return undefined;
  }

  return browserLocales.map((locale) => {
    const trimmedLocale = locale.trim();

    return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
  });
}

/*
  getLanguage choice
  */
export const getLanguage = () => {
  const value = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "en";
  return value;
};

/*
 Supported languages
 */
export const supportedLanguages = {
  code: ["en", "ht", "es", "fr"],
  name: ["English", "Haitian", "Espanol", "French"],
};

/*
 setLanguage choice
 */
export const setLanguage = (l) => {
  // console.log(l);
  const langs = supportedLanguages.code;
  langs.includes(l)
    ? localStorage.setItem("lang", l)
    : localStorage.setItem("lang", "en");
};

/* 
Request Verification Link
*/
export const requestVLink = () => {
  // window.open("sms:18764444636;?&body=%23GAMES%20Register", "new_");
  window.open("https://mydigicellive.com/auth2/?goto=https://arenagames.mydigicellive.com/");
  // window.open("https://mydigicellive.com/auth2/?goto=" + window.location.href);
  // alert(1)
};

/* 
Fetch Token
*/
export const fetchUserToken = async (hash) => {
  try {
    const response = await axios.post("verify", { hash: hash });
    // console.log(response);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

/* Auth */
export const authProvider = {
  checkAuth: async () => {
    // if(!localStorage.getItem('auth')){
    //     return false;
    // }
    // const jwt = jwt_decode((localStorage.getItem('auth')));
    // Cookies.get('dluser', 'value');
    // let d = parseInt(Date.now()/1000);
    // if(d < jwt.exp){
    //     return true;
    // } else {
    //     localStorage.removeItem('auth');
    //     return false;
    // }

    const response = await axios.get(
      "https://mydigicellive.com/ms/echo_msisdn.php"
    );
console.log(response, "echo msisdn")
    if (response) {
      if (response.data.length > 7) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  getIdentity: async () => {
    // if(localStorage.getItem('auth')){
    //     const jwt = jwt_decode((localStorage.getItem('auth')));
    //     return { msisdn: jwt.msisdn};
    // } else {
    //     const { msisdn } = {msisdn: ''}
    //     return {msisdn};
    // }
    const response = await axios.get(
      "https://www.mydigicellive.com/ms/get_msisdn.php",
      { withCredentials: true }
    );
    // console.log(response, "getIdentity");
    // alert(response.data.data);
    if (response?.data.data !== null) {
      if (response.data.data.length > 1) {
        return { msisdn: response.data.data };
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  login: (token) => {
    localStorage.setItem("auth", token);
  },
  logout: () => {
    Cookies.remove("dluser", { path: "/", domain: "www.mydigicellive.com" }); // removed!
    // Cookies.set('dluser', '');    
    Cookies.set('dluser', '', { path: "/", domain: ".mydigicellive.com" });
    // console.log("logout");
    window.location.href = "/account";
  }
};

export async function getGameSession() {
  try {
    const response = await dataProvider("GET", "/" + api_file + "?name=game-session", null)
    // console.log(response, "getIdentity");
    return response?.data;
  } catch (error) {
    throw new Error(error);
  }
}

// -------------------------------------------------------------------

export async function dataProvider(method, url, data) {
  try {
    const response = await axios_def({
      method: method,
      url: url,
      data,
      // headers: { Authorization: "Bearer " + getToken() },
    });
    // console.log(response, "provider")
    return response;
  } catch (error) {
    console.log(error.response?.data.data, "dp-prov");
    console.log(error.response.status);
    console.log(error.response.headers);
    // throw new Error(error);
  //   Swal.fire({
  //     toast: true,
  //     text: error.response?.data.data.message?error.response?.data.data.message:'Request failed.',
  //     icon: 'error'
  // })
    return error;
 
  }
}

export async function dataProvider_post(url, data) {
  try {
    const response = await axios_def.post( url,
      data);
    // console.log(response, "provider")
    return response;
  } catch (error) {
    console.error(error);
    // console.log(error.response, "dp-post");
    // console.log(error.response.headers);
    // throw new Error(error);
  //   Swal.fire({
  //     toast: true,
  //     text: error.response?.data.data.message?error.response?.data.data.message:'Request failed.',
  //     icon: 'error'
  // })
  }
}


export function goto(page) {
  window.location = page;
};


export async function checkAuth() {

  // return new Promise((resolve, reject) => {
  const data = await dataProvider("GET", "/" + api_file + "?name=is-member", null)
console.log(data, "checkauth");
  return data;
  // console.log(JSON.stringify(res.data.data), "m");
  //     if (res.data.data === true) { // is a member
  //        resolve(true);
  //     } else {
  //        reject(false);
  //     }
  //   }).catch(err => {
  //     console.log(err);
  //      reject(false);
  //   })
  // })

}

export async function currentGame() {
  const data = await dataProvider("GET", "/" + api_file + "?name=current-game", null)
  return data;
}
// export async function getPowerPlay(id) {
//    const data =  await dataProvider("GET", "/"+api_file+"?name=pp-info&id="+id, null);
//    console.log(data,"util")
//    return data;
// }

export async function getPowerPlay(id) {
  try {
    const response = await axios_def.get("/" + api_file + "?name=pp-info&id=" + id);

    //   fetch('https://www.mydigicellive.com/ms/arena/api.php?name=pp-info&id='+id)
    // .then(response => response.json())
    // .then(data => console.log(data));

    return response;
    // return {'p':9};
  } catch (error) {
    throw new Error(error);
  }
}

export async function myPowerPlays() {
  try {
    const response = await axios_def.get("/" + api_file + "?name=pp-my");

    //   fetch('https://www.mydigicellive.com/ms/arena/api.php?name=pp-info&id='+id)
    // .then(response => response.json())
    // .then(data => console.log(data));

    return response;
    // return {'p':9};
  } catch (error) {
    throw new Error(error);
  }
}
export async function purchasePowerPlay(id) {
  try {
    // const response = await dataProvider("POST", ", {id:id});
    const params = new URLSearchParams();
    params.append('id', id);


    const response = await axios_def.post(api_file + "?name=pp-buy", params);
    // console.log(response, "provider")


    //   fetch('https://www.mydigicellive.com/ms/arena/api.php?name=pp-info&id='+id)
    // .then(response => response.json())
    // .then(data => console.log(data));

    return response
    // return {'p':9};
  } catch (error) {
    throw new Error(error);
  }
}


export async function getSubStat() {
  try {
     const response = await axios_def.post(api_file + "?name=sub-stat", null);
    return response
  } catch (error) {
    throw new Error(error);
  }
}

export async function getProfile() {
  return JSON.parse(sessionStorage.getItem("profile"));
}

export async function getFullProfile() {
  try {
    const response = await dataProvider("GET", "/" + api_file + "?name=is-member", null)
    // console.log(response,"666");
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}
export async function getLastPP() {
  try {
    const response = await dataProvider("GET", "/" + api_file + "?name=last-pp", null)
    // console.log(response,"666");
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}


export function myLoader(){
  Swal.fire({
    html: `<img height='40' src='${loader}'/><br/>Please wait...`,
    allowOutsideClick: false,
    allowEscapeKey: false,
    showCancelButton: false,
    showConfirmButton: false,
    backdrop: `
  rgba(0,0,123,0.4)
  left top
  no-repeat`
})
}
