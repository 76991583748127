import React from "react";
import {
  makeStyles,
  MuiThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import GamesIcon from "@material-ui/icons/Gamepad";
// import InfoIcon from "@material-ui/icons/NotificationsActive";
import ListIcon from "@material-ui/icons/ListAlt";
import ProfileIcon from "@material-ui/icons/AccountCircle";
import red from "@material-ui/core/colors/red";
import purple from "@material-ui/core/colors/purple";
import { useHistory, withRouter } from "react-router";

const theme = createTheme({
  palette: {
    primary: {
      main: red[700],
    },
    secondary: {
      main: purple[500],
    },
  },
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    borderTop: "1px solid #ccc"
  }
}));

function BottomBar() {
  let history = useHistory();
  const classes = useStyles();
  const [value, setValue] = React.useState(null);

  const push = (page) => {
    // window.location = page;
    history.push(page)
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
    // console.log(newValue);
    switch (newValue) {
      case 0: {
        // window.location = '/digiinfo';
        // console.log("di");
        push("/leaderboard");
        break;
      }
      case 1: {
        push("/play");
        break;
      }
      // case 2: {
      //   push("/chat");
      //   break;
      // }
      case 2: {
        push("/account");
        break;
      }
      default: window.location = '/'
    }

    // event.target.style.color='red'
  };

  return (
    <div className={classes.appBar} style={{zIndex:10}}>
      <MuiThemeProvider theme={theme}>
        <BottomNavigation
          value={value}
          onChange={handleChange}
          showLabels
          className={classes.appBar}
        >
          {/* <BottomNavigationAction label="Info" icon={<InfoIcon />} /> */}
          <BottomNavigationAction label="Leaderboard" icon={<ListIcon />} />
          <BottomNavigationAction label="Play &amp; Win" icon={<GamesIcon />} />          
          <BottomNavigationAction label="My Account" icon={<ProfileIcon />} />
        </BottomNavigation>
      </MuiThemeProvider>
    </div>
  );
}
export default withRouter(BottomBar);