import React, { useState, useEffect } from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { myStyles } from "../components/styles";
import { CardActionArea, Button, Grid, List, ListItem, ListItemText, LinearProgress } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import { useHistory } from "react-router";
import Typography from "@material-ui/core/Typography";
import '../App.css';
// import Avatar from "@material-ui/core/Avatar";
// import Grid from "@material-ui/core/Grid";
// import { myStyles } from "../components/styles";
import Paper from "@material-ui/core/Paper";
// import banner from "../assets/candy-crush-daily-revenue.jpg";
// import LeaderBoard from "../components/LeaderBoard";
import coin from '../assets/money.png';
import howtoplay from '../assets/config/howtoplay.json';
import { currentGame, getFullProfile, dataProvider, api_file, myLoader } from "../util/utility";
// import LinearProgress from '@material-ui/core/LinearProgress';
import Swal from 'sweetalert2'
import { Alert } from '@material-ui/lab';
// import { useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom"
import InstallPWA from "../InstallPWA";

export default function Play(props) {
  const history = useHistory();
  const classes = myStyles();
  const [game, setGame] = useState([]);
  const [img, setImg] = useState();
  // const [profile, setProfile] = useState({});
  const [fullProfile, setFullProfile] = useState({});
  // const [cookies, setCookie] = useCookies();
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  useEffect(() => {
    fetchData();
    fetchProfile();
    // console.log(cookies,"cookies")
    // console.log(document.cookie)
    // doSub();




  }, [])


  var item_cnt = 0;
  async function fetchData() {

    const s = await dataProvider("GET", "/" + api_file + "?name=test", null)
    // console.log(s, "test");


    const data = await currentGame();
    // console.log(data.data.data, "games");
    setGame(data.data.data);
    setImg(`https://www.mydigicellive.com/zero/html5_games/${data.data.data?.id}_digicellive/media/graphics/promo/banners/440x280.png`);
  }

  // const doSub = async () => {
  //   dataProvider("GET", "/" + api_file + "?name=sub", null).then(res => {
  //     // console.log(res, "sub");
  //     // if(res.data)
  //   }).catch(err => {
  //     console.log(err)
  //   })
  // }

  async function fetchProfile() {
    const fullprofile = await getFullProfile();
    setFullProfile(fullprofile.data);
  }



  // async function fetchProfile() {
  //   const data = await getProfile();
  //   setFullProfile(data);
  // }


  // const previewGame = (e) => {
  //   if (window.location.pathname === "/game") {
  //     history.push(props.data.id);
  //   } else {
  //     history.push("/game/" + props.data.id);
  //   }
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // }

  // const goRegister = () => {
  //   history.push('/register');
  // }

  // const fixImg = (e) => {
  //   setImg(`https://www.mydigicellive.com/zero/html5_games/${game?.id}_digicellive/media/graphics/promo/banners/440x280.jpg`);
  // }

  const playGame = (url) => {

    // return console.log(fullProfile)
    // return window.location = url;

    if (!fullProfile) {
      return Swal.fire({
        title: 'ArenaGames Verification',
        text: "Let's get your account ready to Play & Win.",
        icon: 'info',
        // showCancelButton: true,
        confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
        // confirmButtonText: 'Continue'
      }).then((result) => {
        if (result.isConfirmed) {
          history.push('/register');
        }

      });
    }

    if (!fullProfile.rates.subscription || isNaN(fullProfile.rates.subscription)) {

      if (fullProfile.rates.subscription < 0) {
        Swal.fire({ text: 'Not available in your country', icon: 'error' });
        return false;
      }

    }

    if (fullProfile?.payment_active === false || fullProfile?.sub === false) { //not paid or subbed
      return Swal.fire({
        title: 'ArenaGames',
        text: "You will receive free SMS notifications while playing this game. Please confirm your free subscription",
        // text: "You should be subscribed and billed to Play & Win. Daily subscription rate is $" + fullProfile.rates.subscription / 100,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continue'
      }).then((result) => {
        if (result.isConfirmed) {
          // history.push('/account');
          myLoader();
          //do paysub
          dataProvider("POST", "/" + api_file + "?name=do-pay", { op: 'pay' }).then(res => {
            // console.log(res, "subpay");

            if (res?.data?.data) {
              var info = res?.data.data;
              // alert(res.data.error)
              if (info.error === false) { //success
                Swal.close();
                Swal.fire(
                  'Success!',
                  info.message,
                  'success'
                ).then(() => {
                  // window.location = url;
                  window.location.reload();
                })
              } else {
                Swal.close();
                Swal.fire(
                  'Error!',
                  info.message,
                  'warning'
                )
              }
            } else { //no response
              // Swal.close();
              // Swal.fire(
              //   'Timeout!',
              //   'No response',
              //   'error'
              // )
            }
          }).catch(err => {
            // Swal.close();
            console.log(err, "playjs")
          })

        }
      })
    } else {
      window.location = url;
    }



  }
  // console.log(props.data.title, "ok")
  // return game[0].id ? (


  return (

    game[0]?.id ? <div className="body">


      {

        game.map(game =>
          <Paper
            elevation={2}
            style={{ margin: "5px" }}
            key={item_cnt++}
            // className={classes.root}
            onClick={() => playGame(game.href)}
          >


            <CardActionArea>
              <div className="container">

                {/* {game.id ? <img src={game.id ? img : ''} onError={fixImg} style={{ width: '100%' }} /> : <div style="heignt:300px">
              </div>}  */}

                {game.id && game.id !== undefined ? <img alt="game" src={`https://www.mydigicellive.com/zero/html5_games/${game.id}_digicellive/media/graphics/promo/banners/440x280.png`} onError={(e) => e.target.src = `https://www.mydigicellive.com/zero/html5_games/${game.id}_digicellive/media/graphics/promo/banners/440x280.jpg`} style={{ width: '100%' }} /> : <div style={{ heignt: 300 }}>
                </div>}

                <div className="topLeftTringle" style={{ padding: 0, textAlign: 'center', position: 'absolute', top: '0px', left: '0px', color: 'white' }} ></div>

                <div style={{ padding: 0, transform: 'rotate(-45deg)', textAlign: 'center', position: 'absolute', top: 28, left: '10px', color: 'white' }} ><Typography variant="subtitle1">Target Score</Typography><Typography variant="h5"><b>{game.target_score}</b><img src={coin} width={28} style={{ verticalAlign: 'middle' }} /></Typography></div>


                {
                  game?.label ? <div className="gameLabel" style={{ padding: 0, textAlign: 'center', position: 'relative', top: '-5px', left: '0px', color: 'white' }} >{game.label}</div> : ''
                }




                {/* <div style={{ padding: 0, textAlign: 'center', position: 'absolute', bottom: 100, left: '0', color: 'white' }} ><Typography variant="subtitle1">Game of the Week</Typography></div> */}
              </div>
              <div>

              </div>
              <CardContent>
                <Typography gutterBottom component="h6">
                  {game?.title?.substring(0, 29) +
                    (game?.title?.length > 29 ? "..." : "")}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {game?.description?.substring(0, 58) +
                    (game?.description?.length > 58 ? ".." : "")}
                </Typography>
                <div className="para center">
                  <Button
                    variant="outlined"
                    size="large"
                    type="submit"

                    // className={classes.digiButton}
                    style={{
                      // borderImageSlice: 1,
                      // borderWidth: 3,
                      borderRadius: 10,
                      background: '#d131f9',
                      backgroundColor: "linear-gradient(to left, #d131f9, #8879fb)",
                      fontSize: '1.2em',
                      fontWeight: 600,
                      color: '#fff',
                      marginTop: 10,
                      width: '100%'
                    }}
                    onClick={() => playGame(game.href)}
                  > Play </Button>
                </div>
              </CardContent>


            </CardActionArea>
          </Paper>
        )
      }


      <Grid conatiner spacing={2} style={{ marginTop: 20 }}>
        <Grid xs={12}>
          <Typography style={{ textAlign: "center" }} variant="h6">How to Play</Typography>
        </Grid>

        <Grid xs={12}>
          <List>

            {howtoplay.map(i => <ListItem>
              <ListItemText primary={i.title} secondary={i.description} />
            </ListItem>)}


          </List>
        </Grid>
      </Grid>


      {/* <Paper
        elevation={2}
        style={{ margin: "5px" }}
      // className={classes.root}
      onClick={playGame}
      >
        <CardActionArea>
          <div class="container">
            {game.id ? <img src={game.id ? img : ''} onError={fixImg} style={{ width: '100%' }} /> : <div style="heignt:300px">
            </div>}

            <div className="topLeftTringle" style={{ padding: 0, textAlign: 'center', position: 'absolute', top: '0px', left: '0px', color: 'white' }} ></div>

            <div style={{ padding: 0, transform: 'rotate(-45deg)', textAlign: 'center', position: 'absolute', top: 28, left: '10px', color: 'white' }} ><Typography variant="subtitle1">Target Score</Typography><Typography variant="h5"><b>{game.target_score}</b><img src={coin} width={28} style={{ verticalAlign: 'middle' }} /></Typography></div>
          </div>
          <div>

          </div>
          <CardContent>
            <Typography gutterBottom component="h6">
              {game?.title?.substring(0, 29) +
                (game?.title?.length > 29 ? "..." : "")}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {game?.description?.substring(0, 38) +
                (game?.description?.length > 38 ? ".." : "")}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Paper> */}


      {/* <div className="para center">
        {profile?.msisdn?.length > 7 ?
          <Button
            variant="outlined"
            size="large"
            className={classes.digiButton}
            onClick={playGame}

          >PLAY &amp; WIN
          </Button> : <Button
            variant="outlined"
            size="large"
            className={classes.digiButton}
            onClick={goRegister}

          >SUBSCRIBE &amp; PLAY
          </Button>
        } */}
      {/* <small><br/>Game ends in 3 days</small> */}
      {/* <Chip style={{ marginTop: 10 }} label={"Game ends in " + game.time?.days_left + " days"} color="default" variant="outlined" /> */}
      {/* </div> */}
      {/* <LeaderBoard /> */}



    </div> : <div>

    </div>
  )
}

// export const MemoizedGameItem = React.memo(GameItem);