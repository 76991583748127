// import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

/* Pages */
// import Home from "./pages/Home";
import Leaderboard from "./pages/Leaderboard";
import Play from "./pages/Play";
import Register from "./pages/Register";
import Account from "./pages/Account";
import PowerPlay from './pages/PowerPlay';
import Subscription from './pages/Subscription';

import Bar from "./components/Bar";
import BottomBar from "./components/BottomBar";
import UnauthRoute from "./components/UnauthRoute";
import PrivateRoute from "./components/PrivateRoute";
// import { Subscriptions } from '@material-ui/icons';
import Faq from './pages/Faq';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-138580990-1');
ReactGA.pageview(window.location.pathname + window.location.search);


function App() {
  return (
    <div>
      <Router>
      {/* <Router basename="/arenagames/"> */}
        <Bar />

        <div className="main">
          <Switch>
          {/* <Route path="/" exact>
              <Play />
            </Route> */}
            <Route
            component={Play}
            path="/"
            exact
          /> 

            {/* <Route */}
            <UnauthRoute
            component={Register}
            path="/register"
            exact
          />  
          
          <Route
            component={Faq}
            path="/faq"
            exact
          /> 
            <Route
            component={Subscription}
            path="/subscription"
            exact
          /> 
{/*           
            <Route path="/play" exact>
              <Play data={ {id:'knife-master', title:"Knife Master", description_eng : 'Play this nice game and win by gambling'}} />
            </Route>          
           */}
            <Route
            component={Play}
            path="/play"
            exact
          /> 
            <PrivateRoute
            component={Subscription}
            path="/subscription"
            exact
          /> 
            <PrivateRoute
            component={Account}
            path="/account"
            exact
          /> 
            <PrivateRoute
            component={PowerPlay}
            path="/powerplay"
            exact
          /> 
           <PrivateRoute
            component={Leaderboard}
            path="/leaderboard"
            exact
          /> 
            {/* <Route path="/leaderboard" exact>
              <Leaderboard />
            </Route> */}
          </Switch>  
     
        </div>

        <BottomBar />
      </Router>

    </div>
  );
}

export default App;
