import React, { useEffect, useState } from "react"
// import LeaderBoard from "../components/LeaderBoard"
import "../App.css";
import { myStyles } from "../components/styles";
import Alert from '@material-ui/lab/Alert';
import { Grid, Paper, Typography, Button, Chip, Divider } from "@material-ui/core";
import { AttachMoney, Money } from '@material-ui/icons';
// import {useHistory} from "react-router"
import { useHistory, useLocation } from "react-router-dom";
import coin from '../assets/money.png';
import { getPowerPlay, purchasePowerPlay, myLoader } from "../util/utility";
import Swal from 'sweetalert2';
import { AppContext } from "../contexts/App";


export default function PowerPlay(props) {
  const classes = myStyles();
  const history = useHistory();
  const booster = React.useContext(AppContext);
  const [powerplay, setPowerPlay] = useState();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const queryID = useQuery().get("id");
  async function fetchData() {

    let p = queryID;
    // console.log(p, "p");

    if (!p) return false;
    // console.log(p)
    const data = await getPowerPlay(p);
    // console.log(data.data.data[0], "setpp")

    if (data.data.data[0]) {
      setPowerPlay(data.data.data[0]);
    } else {
      setPowerPlay(false);
    }
  }
  window.dataLayer = window.dataLayer || [];
  function gtag() { window.dataLayer.push(arguments); }
  //  gtag('js', new Date()); gtag('config', 'AW-10982165912');

  const doPurchasePowerPlay = async () => {
    myLoader();
    let p = queryID;
    // const {purchase_code} = await purchasePowerPlay(p);

    try {
      const data = await purchasePowerPlay(p);

      if (data?.data.data[0]) { //call made ok
        Swal.close();
        // if(data.data.data.data.data.purchase_code === 0){
        if (p === data.data.data[0].purchase_code) {


          gtag('event', 'conversion', {
            'send_to': 'AW-10982165912/f2jiCOr8yOADEJib2vQo',
            'transaction_id': ''
          });




          Swal.fire(
            'Success',
            'Congrats you got a Score Booster',
            'success'
          )
          history.push("/account")
        } else { //err
          Swal.fire(
            'Error!',
            'PowerPlay offer not found.',
            'warning'
          )
        }
      } else { //err
        let pcode = data.data.data.data.data.purchase_code;
        Swal.close();
        Swal.fire(
          'Error!',
          'Could not purchase Score Booster. [' + pcode + ']',
          'warning'
        )
      }


    } catch (error) {
      console.error(error, "powerplay")
    }

    // console.log(data.data.data.data.data, "purchasePowerPlay")


  }


  const confirmPurchase = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Purchase this Score Booster",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {

        doPurchasePowerPlay();

        //   Swal.fire(
        //     'Deleted!',
        //     'Your file has been deleted.',
        //     'success'
        //   )
      }
    })
  }




  useEffect(() => {
    // setPowerPlay(true)
    fetchData();
    // console.log(booster.data.data,"booster") 
  }, [])
  return powerplay?.game_id ? (
    <div className="body">
      {/* <h3>Leader Board</h3> */}
      <div style={{ padding: 20, background: '#eee' }}>
        <Grid container>
          <Grid item xs={12}>
            <Money style={{ color: '#B10FBC', fontSize: 34 }} />
            <small style={{ color: "#7c0784", verticalAlign: "top", marginLeft: 5 }}>
              Purchase Score Booster
            </small>
          </Grid>
        </Grid>
      </div>

      <Paper className="card" style={{ background: 'linear-gradient(to left, #743ad5, #d53a9d)', color: 'white' }}>
        <img src={`https://www.mydigicellive.com/zero/html5_games/${powerplay.game_id}_digicellive/media/graphics/promo/banners/440x280.png`} onError={(e) => e.target.src = `https://www.mydigicellive.com/zero/html5_games/${powerplay.game_id}_digicellive/media/graphics/promo/banners/440x280.jpg`} style={{ width: '100%' }} />
        {/* <Typography variant="h6">
         Get this PowerPlay and make <img src={coin} width={28} style={{ verticalAlign: 'middle' }} /> <span className="coin">10X</span> tokens the next time you beat the target score of {powerplay.target_score}.
            </Typography> */}

        <div style={{ background: "#fff", color: "#212121", padding: 9 }}>
          <Typography style={{ textAlign: "center" }} variant="h6">Boost YOUR Points!</Typography>

          <div>
            <Typography variant="body" style={{ lineHeight: 2 }}>
              Get this Score Booster  <Button color="primary" variant="contained" size="small" onClick={confirmPurchase}>${powerplay?.cost / 100}</Button> and <b>Multiply your Coins</b> the <b>NEXT</b> time you play!

            </Typography>
          </div>

          <div style={{marginTop:10}}>
            <Grid container spacing={1}>
              {booster.data.data.map(i =>
               <>
                  {/* Make a score of at least {i.target_ratio * powerplay.target_score} for <img src={coin} width={28} style={{ verticalAlign: 'middle' }} /> <span className="coin">{i.multiplier * 3} coins</span> */}
                  <Grid item xs={6} sm={6}> <div>EARN <b>{i.multiplier * 3} coins</b></div></Grid>
                  <Grid item xs={6} sm={6}><div>Make a SCORE of at least <b>{i.target_ratio * powerplay.target_score}</b> pts</div></Grid>



                  <Grid item xs={12}> <Divider /></Grid>


                </>)
              }
            </Grid>

            <div>
            <Typography variant="body" style={{ lineHeight: 2 }}>
          

              Click here <Button color="primary" variant="contained" size="small" onClick={() => history.push("/faq")}>FAQ</Button> for more details.
            </Typography>
          </div>

          </div>
        </div>




      </Paper>

      <div className="para center">
        <Button
          variant="outlined"
          size="large"
          className={classes.digiButton}
          onClick={confirmPurchase}
        >
          Get Score Booster &nbsp; <Chip
            icon={<AttachMoney />}
            label={powerplay?.cost / 100}
            style={{ fontSize: '14pt' }}
            // onDelete={handleDelete}
            color="secondary"
          />



        </Button>
      </div>


    </div>
  ) : (

    powerplay == false ? <div className="para center" style={{ padding: 20 }}>

      <Alert severity="error">This Score Booster has been used already or invalid — Sorry!</Alert>
      <Button
        variant="outlined"
        size="large"
        className={classes.digiButton}
        onClick={() => history.push("/play")}
      >
        PLAY &amp; WIN

      </Button>
    </div> : ""



  )
}