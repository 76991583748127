import { makeStyles } from "@material-ui/core/styles";
import "../App.css";

export const myStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  section1: {
    margin: theme.spacing(3, 2),
    fontFamily: "Roboto",
  },
  section2: {
    margin: theme.spacing(2),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  digiButton: {
    borderImageSlice: 1,
    borderWidth: 3,
    borderRadius: "9px",
    borderImageSource: "linear-gradient(to left, #743ad5, #d53a9d)",
    fontSize:'1.2em',
    fontWeight:600,
    color:'#d53a9d',
    marginTop:10,
    width:'100%'
  },
}));