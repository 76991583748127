import React, { useEffect, useState } from "react"
// import LeaderBoard from "../components/LeaderBoard"
import "../App.css";
import { myStyles } from "../components/styles";
// import Alert from '@material-ui/lab/Alert';
import { Grid, Paper, Typography, Button, Chip } from "@material-ui/core";
import { AttachMoney, DoneOutline, HighlightOff, VerifiedUser } from '@material-ui/icons';
// import {useHistory} from "react-router"
import { useHistory } from "react-router-dom";
// import coin from '../assets/money.png';
import { getSubStat, purchasePowerPlay } from "../util/utility";
import Swal from 'sweetalert2'

export default function Subscription(props) {
  const classes = myStyles();
  const history = useHistory();

  // const [data, setData] = useState();
  const [status, setStatus] = useState();

  async function fetchData() {
    const data = await getSubStat();
    let d = data.data.data;
    // console.log(data.data.data, "substat")

    let s = {
      subscription:'',
      billing:''
    }

    if (d?.subscription) {
      s.subscription = 'active'
    } else {
      s.subscription = 'inactive'
    }

    if (d.standing.up > d.standing.bill) {
     s.billing = 'due';
    } else {
      s.billing = 'active'
    }

    setStatus(s);

  }

  const doPurchasePowerPlay = async () => {
    let p = '';
    const data = await purchasePowerPlay(p);
    // console.log(data, "purchasePowerPlay")

    if (data?.data.data[0]) { //call made ok
      if (p === data.data.data[0].purchase_code) {
        Swal.fire(
          'Success',
          'Congrats you got a PowerPlay',
          'success'
        )
        history.push("/account")
      } else { //err
        Swal.fire(
          'Error!',
          'PowerPlay offer not found.',
          'warning'
        )
      }
    } else { //err
      Swal.fire(
        'Error!',
        'Could not purchase PowerPlay.',
        'warning'
      )
    }
  }


  const confirmPurchase = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Purchase this PowerPlay",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {

        doPurchasePowerPlay();

        //   Swal.fire(
        //     'Deleted!',
        //     'Your file has been deleted.',
        //     'success'
        //   )
      }
    })
  }




  useEffect(() => {
    // setPowerPlay(true)
    fetchData();
    // console.log(data, "sub effect");
  }, [])


  return (
    <div className="body">
      {/* <h3>Leader Board</h3> */}
      <div style={{ padding: 20, background: '#eee' }}>
        <Grid container>
          <Grid item xs={12}>
            <VerifiedUser style={{ color: '#B10FBC', fontSize: 34 }} />
            <small style={{ color: "#7c0784", verticalAlign: "top", marginLeft: 5 }}>
              Subscription
            </small>
          </Grid>
        </Grid>
      </div>

      {status ? <Paper className="card" style={status !== 'inactive' ? { background: 'linear-gradient(to left, #743ad5, #d53a9d)', color: 'white' } : { background: 'red', color: 'white' }}>
        {/* <img src={`https://www.mydigicellive.com/zero/html5_games/${powerplay.game_id}_digicellive/media/graphics/promo/banners/440x280.png`} onError={(e)=> e.target.src=`https://www.mydigicellive.com/zero/html5_games/${powerplay.game_id}_digicellive/media/graphics/promo/banners/440x280.jpg`} style={{ width: '100%' }} /> */}
        <Typography variant="h6">
        {status && status.subscription === 'active' ? <DoneOutline/>:<HighlightOff/>
        } {status && status !== '' ? (`Your subscription is ${status.subscription}.`):''}
        </Typography>
        <Typography variant="h6">
        {status && status === 'active' ? <DoneOutline/>:<HighlightOff/>}  {status && status !== '' ? `Your billing is ${status.billing}.` : ``} {}
        </Typography>
      </Paper> : ''}

      <div className="para center">
        <Button
          variant="outlined"
          size="large"
          className={classes.digiButton}
          onClick={confirmPurchase}
        >
          Show Sub status &nbsp; <Chip
            icon={<AttachMoney />}
            // label={powerplay?.cost/100}
            style={{ fontSize: '14pt' }}
            // onDelete={handleDelete}
            color="secondary"
          />



        </Button>
      </div>


    </div>
  )
}