import React from "react"
import LeaderBoard from "../components/LeaderBoard"
import "../App.css";
import { myStyles } from "../components/styles";
import {Grid, Button} from '@material-ui/core'
import {FormatListNumbered} from '@material-ui/icons';
import {useHistory} from "react-router"
import InstallPWA from "../InstallPWA";

export default function Leaderboard() {
    const classes = myStyles();
    const history = useHistory();
    return (
        <div className="body">      
            {/* <h3>Leader Board</h3> */}
            <div style={{padding:20, background:'#eee'}}>
                <Grid container>
                    <Grid item xs={12}>
                        <FormatListNumbered style={{color:'#B10FBC', fontSize:34}} />
                   
                        <small style={{color:"#7c0784", verticalAlign:"top", marginLeft:5}}>
                            Top 10 players this week
                        </small>
                    </Grid>
                </Grid>
            </div>

            <div className="para center">
        <Button
          variant="outlined"
          size="large"
          className={classes.digiButton}
          onClick={() => history.push("/play")}
        >
          PLAY &amp; WIN
             
             </Button>

             <div>
            <InstallPWA />
           
            </div>
             </div>
            <LeaderBoard />
       
        </div>
    )
}